import React from "react";

import styles from "./styles.module.scss";
import Button, { IButton } from "../../../controls/Button/Button";
import useCloseModal from "../../../../utils/hooks/useCloseModal";
interface IModalButtonClose extends Partial<IButton> {
  close?: () => void;
}
export default function ModalButtonClose(props: IModalButtonClose) {
  const close = useCloseModal();
  function closeModal() {
    if (!props.close) {
      return close();
    }
    props.close();
  }
  return (
    <div className={styles.buttonClose}>
      <Button type="primary" onClick={closeModal} {...props}>
        Закрыть
      </Button>
    </div>
  );
}
