import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import OrderContent from "./OrderContent/OrderContent";
import ReservesBlock from "./ReservesBlock/ReservesBlock";
import ModalCancel from "@/components/modals/ModalCancel/ModalCancel";
import showToast from "@/utils/helpers/toast/showToast";
import StatusSwitcher from "./OrderContent/StatusSwitcher/StatusSwitcher";
import ModalEmptyNext from "@/components/modals/ModalEmptyNext/ModalEmptyNext";
import TimerContent from "@/components/shared/Timer/TimerContent/TimerContent";
import ModalMakePaid from "@/components/modals/ModalMakePaid/ModalMakePaid";
import useGetOrderTypeData from "@/utils/hooks/stateSwitchers/useGetOrderTypeData";
import { useGetPayoutLimitsQuery } from "@/store/payoutLimits/payoutLimits.api";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useGetExecutorQuery } from "@/store/executor/executor.api";
import { useAppSelector } from "@/store/hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useGetActiveOrderQuery,
  useLazyGoNextOrderQuery,
} from "@/store/orders/orders.api";
import {
  closeModalEmptyNext,
  openModalEmptyNext,
  selectIsModalEmptyNextOpen,
} from "@/store/ui/ui.slice";

export default function OrderPage() {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage" });
  const isModalEmptyNextOpen = useAppSelector(selectIsModalEmptyNextOpen);
  const dispatch = useDispatch();
  const getTypeData = useGetOrderTypeData();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isOrderEmpty, setIsOrderEmpty] = useState(false);
  const [isOrderError, setIsOrderError] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);

  const { data: executorData } = useGetExecutorQuery();
  const isTokenEnabled = executorData?.data?.state === "enabled";
  const {
    currentData: activeOrderData,
    isLoading: isActiveOrderLoading,
    error: activeOrderError,
    isError: isActiveOrderError,
    refetch: refetchActive,
    isUninitialized: isActiveOrderUninitialized,
  } = useGetActiveOrderQuery(undefined, {
    pollingInterval: isOrderEmpty || isOrderError ? 30000 : 10000,
    skip: !isTokenEnabled,
  });

  const [
    goToNextOrder,
    { isLoading: isNextOrderLoading, error: nextOrderError },
  ] = useLazyGoNextOrderQuery();
  const { isLoading: isReservesLoading, refetch: refetchLimits } =
    useGetPayoutLimitsQuery();

  async function getNextOrder() {
    try {
      await goToNextOrder().unwrap();
    } catch (e: any) {
      e?.status === 404 && dispatch(openModalEmptyNext());
    } finally {
      !isActiveOrderUninitialized && refetchActive();
    }
  }

  function sendTypeToast() {
    const typeData = getTypeData(activeOrderData?.data.state || "");
    const type =
      typeData.type === "danger"
        ? "error"
        : typeData.type === "accent"
        ? "info"
        : typeData.type;
    showToast({
      type: type as "success",
      value: typeData.title,
      icon: typeData.icon,
      description: typeData.description || "",
    });
    setVerifyMode(false);
  }

  useEffect(() => {
    setIsOrderEmpty(!activeOrderData?.data?.id);
    if (verifyMode) {
      sendTypeToast();
    }
    if (activeOrderData?.data.state === "completed") {
      refetchLimits();
    }
  }, [activeOrderData]);

  useEffect(() => {
    setIsOrderError(Boolean(activeOrderError));
  }, [activeOrderError]);

  const initialDate = new Date(
    activeOrderData?.data?.expire_at?.toString()?.replace(/-/g, "/") as string
  ).getTime();
  const countDownStart = new Date(
    activeOrderData?.data?.updated_at?.toString().replace(/-/g, "/") as string
  ).getTime();

  if ((isActiveOrderLoading || isReservesLoading) && !isActiveOrderError) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <ModalEmptyNext
        isOpen={isModalEmptyNextOpen}
        onClose={() => dispatch(closeModalEmptyNext())}
      />
      <ModalCancel
        orderData={activeOrderData?.data}
        isOpen={Boolean(searchParams.get("cancel_modal"))}
        onClose={() => {
          searchParams.delete("cancel_modal");
          setSearchParams(searchParams);
        }}
      />
      <ModalMakePaid
        data={activeOrderData?.data}
        isOpen={Boolean(searchParams.get("make_paid_modal"))}
        onClose={() => {
          searchParams.delete("make_paid_modal");
          setSearchParams(searchParams);
        }}
      />
      <ReservesBlock />
      <div className={styles.container}>
        <StatusSwitcher
          orderData={activeOrderData?.data}
          isNextOrderLoading={isNextOrderLoading}
          getNextOrder={getNextOrder}
          nextOrderError={nextOrderError}
          orderError={activeOrderError}
          state={activeOrderData?.data.state || ""}
          isTokenEnabled={isTokenEnabled}
        />
        {activeOrderData?.data.state === "processing" &&
          !isActiveOrderError && (
            <TimerContent
              countDownStart={countDownStart}
              initialDate={initialDate}
              description={t("timer.description")}
            />
          )}
        {activeOrderData?.data?.id && isTokenEnabled && !isOrderError && (
          <OrderContent
            data={activeOrderData.data}
            verifyMode={verifyMode}
            setVerifyMode={setVerifyMode}
          />
        )}
      </div>
    </>
  );
}
