async function sha256Hash(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
}

async function getAudioFingerprint() {
  return new Promise((resolve, reject) => {
    try {
      const audioContext = new (window.OfflineAudioContext ||
        window.OfflineAudioContext)(1, 6000, 44100);

      const oscillator = audioContext.createOscillator();
      oscillator.type = "triangle";
      oscillator.frequency.setValueAtTime(10000, audioContext.currentTime);

      const gainNode = audioContext.createGain();
      gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);

      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      oscillator.start();
      audioContext
        .startRendering()
        .then((buffer) => {
          const hash = buffer
            .getChannelData(0)
            .reduce((sum, value) => sum + value ** 2, 0);
          resolve(hash.toString());
        })
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
}

function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

async function getWebGLInfo() {
  try {
    const canvas = document.createElement("canvas");
    const gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (!gl) return "Not supported";

    const debugInfo = gl.RENDERER;
    return debugInfo
      ? {
          renderer: gl.getParameter(gl.RENDERER),
        }
      : "Not supported";
  } catch {
    return "Not supported";
  }
}

async function getCanvasFingerprint() {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "";

  ctx.textBaseline = "top";
  ctx.font = "14px Arial";
  ctx.fillStyle = "#f60";
  ctx.fillRect(0, 0, 150, 50);
  ctx.fillStyle = "#069";
  ctx.fillText("Hello, world!", 2, 15);
  ctx.strokeStyle = "red";
  ctx.strokeRect(5, 5, 140, 30);

  return canvas.toDataURL();
}

function getLanguages() {
  return navigator.languages?.join(", ") || navigator.language || "Unknown";
}

export const getFingerprint = async () => {
  const webgl = await getWebGLInfo();
  const rawData = {
    platform: navigator.platform || "Unknown",
    timezone: getTimezone(),
    languages: getLanguages(),
    webgl_renderer: webgl.renderer || webgl,
    canvas_fp: await getCanvasFingerprint(),
    screen: `${window.screen.width}x${window.screen.height}`,
    color_depth: window.screen.colorDepth || 0,
    memory: navigator.deviceMemory || 0,
    concurrency: navigator.hardwareConcurrency || 0,
    touch: "ontouchstart" in window || navigator.maxTouchPoints > 0,
    audio_fp: await getAudioFingerprint(),
  };

  const jsonString = JSON.stringify(rawData);
  return await sha256Hash(jsonString);
};
