import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { useSearchParams } from "react-router-dom";
import { IOrderItem } from "../../../store/orders/orders.types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import {
  selectExecutorBankName,
  setExecutorBankName,
} from "../../../store/orders/orders.slice";
import Text from "../../../components/typography/Text/Text";
import ModalSelectBank from "../../../components/modals/ModalSelectBank/ModalSelectBank";
import ModalOrderAccept from "../../../components/modals/ModalOrderAccept/ModalOrderAccept";
import CancelBlock from "./CancelBlock/CancelBlock";
import AmountBlock from "../AmountBlock/AmountBlock";
import RequisiteBlock from "./RequisiteBlock/RequisiteBlock";
import DetailsBlock from "./DetailsBlock/DetailsBlock";
import BankChose from "./BankChose/BankChose";
import useGetBank from "../../../utils/hooks/useGetBank";
import NextOrderButton from "./NextOrderButton/NextOrderButton";
import AutoPayoutButton from "./AutoPayoutButton/AutoPayoutButton";
import ProcessingWrapper from "./ProcessingWrapper/ProcessingWrapper";
import Proofs from "./Proofs/Proofs";
import { useGetExecutorQuery } from "@/store/executor/executor.api";

export interface IOrderContent {
  data: IOrderItem;
  verifyMode: boolean;
  setVerifyMode: (value: React.SetStateAction<boolean>) => void;
}

export default function OrderContent({
  data,
  setVerifyMode,
  verifyMode,
}: IOrderContent) {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage" });
  const [searchParams, setSearchParams] = useSearchParams();
  const [bankError, setBankError] = useState<string>();
  const { data: executorData } = useGetExecutorQuery();
  const dispatch = useAppDispatch();
  const selectedBankName = useAppSelector(selectExecutorBankName);

  const bank = useGetBank({ bankAlias: data.bank_name });
  const selectedBank = useGetBank({ bankAlias: selectedBankName });
  const isProcessing = data.state === "processing" || data.state === "pending";

  return (
    <div className={styles.container} style={{ gap: isProcessing ? 30 : 10 }}>
      <ModalOrderAccept
        isOpen={Boolean(searchParams.get("auto_payout_modal"))}
        onClose={() => {
          searchParams.delete("auto_payout_modal");
          setSearchParams(searchParams);
        }}
        id={searchParams.get("auto_payout_modal") || ""}
      />
      <ModalSelectBank
        selectedBankName={selectedBankName}
        setSelectedBankName={(name: string) =>
          dispatch(setExecutorBankName(name))
        }
        isOpen={Boolean(searchParams.get("bank_modal"))}
        onClose={() => {
          searchParams.delete("bank_modal");
          setSearchParams(searchParams);
        }}
      />

      {data.payment_functionality_visible && <AutoPayoutButton data={data} />}
      {isProcessing ? (
        <ProcessingWrapper
          title={
            <>
              <Text type="muted">1.</Text>{" "}
              {executorData?.data.cashin_enabled && data.cashin_available
                ? t("processingCashInTexts.amount")
                : t("processingTexts.amount")}
            </>
          }
        >
          <AmountBlock
            isProcessing={isProcessing}
            amount={data.amount}
            currency={data.currency}
          />
        </ProcessingWrapper>
      ) : (
        <AmountBlock
          isProcessing={isProcessing}
          amount={data.amount}
          currency={data.currency}
        />
      )}
      {isProcessing ? (
        <ProcessingWrapper
          title={
            <>
              <Text type="muted">2.</Text>{" "}
              {executorData?.data.cashin_enabled && data.cashin_available
                ? t("processingCashInTexts.requisites")
                : t("processingTexts.requisites")}
            </>
          }
        >
          <RequisiteBlock
            bank={bank as any}
            data={data}
            isProcessing={isProcessing}
          />
        </ProcessingWrapper>
      ) : (
        <RequisiteBlock
          bank={bank as any}
          data={data}
          isProcessing={isProcessing}
        />
      )}
      {data.payment_functionality_visible && (
        <>
          {executorData?.data.cashin_enabled && data.cashin_available ? (
            <></>
          ) : (
            <>
              {isProcessing ? (
                <ProcessingWrapper
                  title={
                    <>
                      <Text type="muted">3.</Text>{" "}
                      {t("processingTexts.bankChose")}
                    </>
                  }
                >
                  <BankChose
                    isProcessing={isProcessing}
                    bankError={bankError}
                    selectedBankName={selectedBankName}
                    selectedBank={selectedBank}
                  />
                </ProcessingWrapper>
              ) : (
                <BankChose
                  isProcessing={isProcessing}
                  bankError={bankError}
                  selectedBankName={selectedBankName}
                  selectedBank={selectedBank}
                />
              )}
            </>
          )}
        </>
      )}
      <Proofs
        data={data}
        setVerifyMode={setVerifyMode}
        verifyMode={verifyMode}
        isProcessing={isProcessing}
        setBankError={setBankError}
      />
      {data.is_final_state && <NextOrderButton />}

      <DetailsBlock data={data} />

      {!data.is_final_state && data.state !== "manual_verification" && (
        <CancelBlock
          onCancel={() => {
            searchParams.set("cancel_modal", "1");
            setSearchParams(searchParams);
          }}
        />
      )}
    </div>
  );
}
