import React from "react";

import styles from "./styles.module.scss";

import { useTranslation } from "react-i18next";
import Text from "@/components/typography/Text/Text";
import ModalButtonClose from "../ModalBase/ModalButtonClose/ModalButtonClose";
import Block from "@/components/shared/Block/Block";
import ErrorParser, {
  IErrorParser,
} from "@/components/shared/ErrorParser/ErrorParser";
import ModalBase, { IModalBase } from "../ModalBase/ModalBase";

interface IModalError extends Omit<IModalBase, "children"> {
  title?: string;
  error: any;
  translates?: IErrorParser["translates"];
  messageTranslates?: IErrorParser["messageTranslates"];
}

export default function ModalError({
  title,
  error,
  translates,
  messageTranslates,
  ...modalSmallProps
}: IModalError) {
  const { t } = useTranslation();
  return (
    <ModalBase shareDisable {...modalSmallProps}>
      <Block gap={30}>
        <div>
          <h3 className={styles.title}>
            <Text type="danger">{title || t("Error")}</Text>
          </h3>
          <p className={styles.subTitle}>
            <ErrorParser
              translates={translates}
              messageTranslates={messageTranslates}
              error={error}
            />
          </p>
        </div>
        <ModalButtonClose close={modalSmallProps.onClose} />
      </Block>
    </ModalBase>
  );
}
