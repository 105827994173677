import React, { useEffect, useState } from "react";

import styles from "../styles.module.scss";

import ProcessingWrapper from "../ProcessingWrapper/ProcessingWrapper";
import Text from "@/components/typography/Text/Text";
import FileInputDrop from "@/components/files/FileInputDrop/FileInputDrop";
import VerificationFailedBlock from "../VerificationFailedBlock/VerificationFailedBlock";
import Links from "./Links/Links";
import Button from "@/components/controls/Button/Button";
import { motion } from "framer-motion";
import { IOrderContent } from "../OrderContent";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { deleteFile, selectFile } from "@/store/files/files.slice";
import { useCompleteOrderMutation } from "@/store/orders/orders.api";
import { selectExecutorBankName } from "@/store/orders/orders.slice";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motionButtonProps } from "@/utils/styles/motionSettings";
import { CircularProgress } from "@mui/material";
import { useGetExecutorQuery } from "@/store/executor/executor.api";

export interface Link {
  value: string;
  error: string | undefined;
  icon?: React.ReactNode;
  success?: boolean;
}

export interface IProofsProps extends IOrderContent {
  isProcessing: boolean;
  setBankError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const LINKS_DEFAULT = [{ value: "", error: undefined }];

export default function Proofs({
  data,
  setVerifyMode,
  verifyMode,
  isProcessing,
  setBankError,
}: IProofsProps) {
  const { t } = useTranslation(undefined, { keyPrefix: "orderPage" });
  const file = useAppSelector(selectFile);
  const selectedBankName = useAppSelector(selectExecutorBankName);
  const dispatch = useAppDispatch();
  const { data: executorData } = useGetExecutorQuery();
  const [files, setFiles] = useState<File[]>([]);
  const [links, setLinks] = useState<Link[]>(LINKS_DEFAULT);
  const [customError, setCustomError] = useState<string>();
  const [completeOrder, { isLoading: isCompleteLoading, error }] =
    useCompleteOrderMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  async function onSubmit() {
    if (
      !(executorData?.data.cashin_enabled && data.cashin_available) &&
      !selectedBankName
    ) {
      setCustomError(t("error.noBank"));
      return setBankError(t("error.noBank"));
    }
    try {
      await completeOrder({
        files: files.length > 0 ? files : undefined,
        id: data.id,
        senderBankName:
          executorData?.data.cashin_enabled && data.cashin_available
            ? data.sender_bank_name || "tinkoff"
            : selectedBankName,
        cashin_links: links.some((link) => link.value)
          ? links.map((link) => link.value)
          : undefined,
      }).unwrap();
      setSearchParams(searchParams);
      setFiles([]);
      dispatch(deleteFile());
      setVerifyMode(true);
    } catch (error) {}
  }

  useEffect(() => {
    file && setFiles([file]);
  }, [file]);

  useEffect(() => {
    setCustomError("");
  }, [files]);

  useEffect(() => {
    if (!data.cashin_links) {
      const responseError: any = error;
      if (
        responseError?.data?.errors?.proofs &&
        responseError?.data?.errors?.proofs[0] === "invalid format"
      ) {
        setCustomError(t("invalidFormat"));
      } else if (
        responseError?.data?.errors?.proofs &&
        responseError?.data?.errors?.proofs[0] === "must be less than 3 MB"
      ) {
        setCustomError(t("maxFileSize"));
      } else {
        setCustomError(JSON.stringify(error));
      }
    }
  }, [error]);

  return (
    <div className={styles.uploaderBlock}>
      {executorData?.data.cashin_enabled && data.cashin_available ? (
        isProcessing ? (
          <ProcessingWrapper
            title={
              <>
                <Text type="muted">3.</Text>{" "}
                {t("processingCashInTexts.receipt")}
              </>
            }
          >
            <Links
              links={links}
              setLinks={setLinks}
              isLoading={isCompleteLoading || verifyMode}
              data={data}
              error={error}
            />
          </ProcessingWrapper>
        ) : (
          <Links
            links={links}
            setLinks={setLinks}
            isLoading={isCompleteLoading || verifyMode}
            data={data}
            error={error}
          />
        )
      ) : (
        <>
          {!data.payment_functionality_visible ? (
            <></>
          ) : (
            <>
              {isProcessing ? (
                <ProcessingWrapper
                  title={
                    <>
                      <Text type="muted">4.</Text>{" "}
                      {t("processingTexts.receipt")}
                    </>
                  }
                >
                  <FileInputDrop
                    value={files}
                    setValue={setFiles}
                    maxFiles={2}
                    placeholder={t("receipt.placeholder")}
                    isLoading={verifyMode || isCompleteLoading}
                  />
                </ProcessingWrapper>
              ) : (
                <FileInputDrop
                  value={files}
                  setValue={setFiles}
                  maxFiles={2}
                  placeholder={t("receipt.placeholder")}
                  isLoading={verifyMode || isCompleteLoading}
                />
              )}
            </>
          )}
        </>
      )}

      {customError && <Text type="danger">{customError}</Text>}
      {((executorData?.data.cashin_enabled && data.cashin_available) ||
        (data.state === "verification_failed" && files.length === 0)) && (
        <VerificationFailedBlock data={data} />
      )}
      {!(executorData?.data.cashin_enabled && data.cashin_available) &&
        files.length === 0 &&
        data.state === "processing" && (
          <motion.button
            {...motionButtonProps}
            className={styles.withoutReceipt}
            onClick={() => {
              searchParams.set("make_paid_modal", "1");
              setSearchParams(searchParams);
            }}
          >
            {t("buttonMakePaid")}
          </motion.button>
        )}
      {((data.payment_functionality_visible &&
        links.length > 0 &&
        links.some((l) => l.value)) ||
        files.length > 0) && (
        <Button
          size="lg"
          withAnimation
          onClick={onSubmit}
          className={styles.submit}
          disabled={isCompleteLoading}
          type="accent"
        >
          {isCompleteLoading ? <CircularProgress size={20} /> : t("submit")}
        </Button>
      )}
    </div>
  );
}
