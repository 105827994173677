import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";

import { Skeleton, Tooltip } from "@mui/material";
import { useAppDispatch } from "@/store/hooks";
import { ReactComponent as SbpIcon } from "@/assets/icons/sbp.svg";
import {
  useChangeExecutorMutation,
  useGetExecutorQuery,
} from "@/store/executor/executor.api";
import { ordersApi } from "@/store/orders/orders.api";
import { useTranslation } from "react-i18next";
import Text from "@/components/typography/Text/Text";
import AppSwitch from "@/components/shared/AppSwitch/AppSwitch";
import classNames from "classnames";
import showToast from "@/utils/helpers/toast/showToast";

export default function SbpBlock() {
  const { t } = useTranslation(undefined, { keyPrefix: "profilePage.sbp" });
  const dispatch = useAppDispatch();
  const [isSbpEnabled, setSbpEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutor] = useChangeExecutorMutation();

  const handleChange = async () => {
    setSbpEnabled((state) => !state);
    if (executorData?.data?.state) {
      try {
        await changeExecutor({ accepts_sbp: !executorData.data.accepts_sbp });
        dispatch(ordersApi.util.resetApiState());
      } catch (e: any) {
        showToast({
          value: "Ошибка",
          type: "error",
          description: JSON.stringify(
            e?.data?.errors || e?.data.message || e?.status
          ),
        });
        setSbpEnabled((state) => !state);
      }
    }
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setSbpEnabled(executorData?.data?.accepts_sbp);
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    if (isSbpEnabled) {
      return <Text type="accent">{t("enabled.switch")}</Text>;
    }
    return <Text type="muted">{t("disabled.switch")}</Text>;
  }

  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    if (isSbpEnabled) {
      return t("enabled.description");
    }
    return t("disabled.description");
  }
  return (
    <div className={classNames(profileStyles.paper, styles.container)}>
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isSbpEnabled ? t("disable") : t("enable")}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isSbpEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>
          {getTokenSwitchText()} <SbpIcon />
        </p>
      </div>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
