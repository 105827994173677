import React, { useState } from "react";

import styles from "./styles.module.scss";
import Text from "../../../../components/typography/Text/Text";
import Button from "../../../../components/controls/Button/Button";
import ModalConfirm from "../../../../components/modals/ModalConfirm/ModalConfirm";
import Block from "../../../../components/shared/Block/Block";
import { IOrderItem } from "../../../../store/orders/orders.types";
import { useManualVerificationMutation } from "../../../../store/orders/orders.api";
import { useTranslation } from "react-i18next";

export default function VerificationFailedBlock({
  data,
}: {
  data: IOrderItem;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: "orderPage.verificationFailedBlock",
  });
  const [manualVerification, { isLoading, error }] =
    useManualVerificationMutation();
  const [isOpen, setIsOpen] = useState(false);

  async function onConfirm() {
    try {
      await manualVerification({ id: data.id }).unwrap();

      setIsOpen(false);
    } catch (error) {}
  }
  return (
    <>
      <ModalConfirm
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        isConfirmLoading={isLoading}
        error={error}
        title={t("modal.title")}
        subTitle={
          <Block>
            <p>{t("modal.description1")}</p>
            <p>{t("modal.description2")}</p>
            <p>
              {t("modal.description3.left")}
              <Text type="accent">
                <b> {t("modal.description3.right")}</b>.
              </Text>{" "}
            </p>
          </Block>
        }
        onConfirm={onConfirm}
      />
      <div className={styles.wrapper}>
        {data.reject_manual_verification ? (
          <p>
            Вам не доступна проверка администрацией по причине:{" "}
            {data?.reject_manual_verification_causes?.map((i, index) => (
              <Text type="danger" key={i}>
                {i}
                {data?.reject_manual_verification_causes?.length - 1 !== index
                  ? ", "
                  : ""}
              </Text>
            ))}
          </p>
        ) : (
          <div className={styles.buttonContainer}>
            <Button withAnimation onClick={() => setIsOpen(true)}>
              {t("confirm")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
