import React from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import { IWrapComponent } from "../../../utils/types/shared";

interface IBlock extends IWrapComponent {
  className?: string;
  gap?: number;
}

export default function Block(props: IBlock) {
  return (
    <div
      className={classNames(styles.block, props.className)}
      style={{ gap: props.gap }}
    >
      {props.children}
    </div>
  );
}
