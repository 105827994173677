import React from "react";
import { useSearchParams } from "react-router-dom";

export default function useCloseModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams);

  function closeModal() {
    for (let key in searchParamsObject) {
      if (key.includes("modal_")) {
        searchParams.delete(searchParamsObject[key]);
      }
    }
    setSearchParams(searchParams);
  }

  return closeModal;
}
