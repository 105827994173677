import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import profileStyles from "../styles.module.scss";
import { Skeleton, Tooltip } from "@mui/material";
import AppSwitch from "../../../components/shared/AppSwitch/AppSwitch";
import { useAppDispatch } from "../../../store/hooks";
import {
  useChangeExecutorStateMutation,
  useGetExecutorQuery,
} from "../../../store/executor/executor.api";
import {
  ordersApi,
  useGetActiveOrderQuery,
} from "../../../store/orders/orders.api";
import Text from "../../../components/typography/Text/Text";
import ModalDisableExecutor from "../../../components/modals/ModalDisableExecutor/ModalDisableExecutor";
import { useTranslation } from "react-i18next";
import ModalError from "@/components/modals/ModalError/ModalError";

export default function TokenBlock() {
  const { t } = useTranslation(undefined, { keyPrefix: "profilePage.token" });
  const dispatch = useAppDispatch();
  const [isTokenEnabled, setTokenEnabled] = useState(true);
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const [changeExecutorState, { error }] = useChangeExecutorStateMutation();
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const { data: activeData } = useGetActiveOrderQuery();

  const isModalMustBeOpen =
    activeData?.data?.state === "verification" ||
    activeData?.data?.state === "pending" ||
    activeData?.data?.state === "processing";
  async function changeState() {
    if (executorData?.data?.state) {
      try {
        await changeExecutorState({
          state:
            executorData?.data?.state === "enabled" ? "disabled" : "enabled",
        }).unwrap();
        setTokenEnabled((state) => !state);
        dispatch(ordersApi.util.resetApiState());
      } catch (e) {
        setIsModalError(true);
      }
    }
  }
  const handleChange = async () => {
    executorData?.data.state === "enabled" && isModalMustBeOpen
      ? setDisableModalOpen(true)
      : changeState();
  };

  useEffect(() => {
    if (executorData?.data?.state) {
      setTokenEnabled(executorData?.data?.state === "enabled");
    }
  }, [executorData]);

  function getTokenSwitchText() {
    if (isExecutorLoading) {
      return <Skeleton height={19} width={128} />;
    }
    if (isTokenEnabled) {
      return <Text type="accent">{t("enabled.switch")}</Text>;
    }
    return <Text type="muted">{t("disabled.switch")}</Text>;
  }
  function getTokenState() {
    if (isExecutorLoading) {
      return <Skeleton width={250} />;
    }
    if (isTokenEnabled) {
      return <Text>{t("enabled.state")}</Text>;
    }
    return <Text type="danger">{t("disabled.state")}</Text>;
  }
  function getTokenDescription() {
    if (isExecutorLoading) {
      return <Skeleton width={"100%"} height={42} />;
    }
    if (isTokenEnabled) {
      return t("enabled.description");
    }
    return t("disabled.description");
  }
  return (
    <div className={profileStyles.paper}>
      <ModalError
        error={error}
        isOpen={isModalError}
        onClose={() => setIsModalError(false)}
      />

      <ModalDisableExecutor
        onDisableAccept={changeState}
        isOpen={disableModalOpen}
        onClose={() => setDisableModalOpen(false)}
      />
      <div className={styles.tokenSwitchContainer}>
        <Tooltip title={isTokenEnabled ? t("disable") : t("enable")}>
          <AppSwitch
            disabled={isExecutorLoading}
            checked={isTokenEnabled}
            onChange={handleChange}
          />
        </Tooltip>
        <p className={styles.tokenSwitchText}>{getTokenSwitchText()}</p>
      </div>
      <p className={styles.tokenState}>{getTokenState()}</p>
      <p className={styles.tokenDescription}>{getTokenDescription()}</p>
    </div>
  );
}
